.legend-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    max-width: 100%;
  }
  
  .legend-item {
    display: flex;
    align-items: center;
    margin-right: 20px;
    margin-bottom: 10px;
  }
  
  .legend-item:last-child {
    margin-right: 0;
  }
  
  .legend-icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    border-radius: 50%;
  }
  
  .legend-label {
    font-size: 14px;
    display: flex;
    align-items: center;
  }
  
  .legend-color {
    width: 10px;
    height: 10px;
    margin-right: 5px;
    border-radius: 50%;
  }
  